// This files primary purpose is for the Marketing part of our websites. 
// Everyone will be able to access this file.
// This file will be used to create the Marketing pages of the website.
// For Doamin & CustomDomain

// ClipboardJS is being used to Copy the text to the clipboard.
import ClipboardJS from 'clipboard';

import "./vendor/jquery_raty.js"
import './vendor/gmaps.js';
import './vendor/datepicker-sv.js'

// Function to create breadcrumbs for article pages


  window.article_object = {
  string_parameterize: function (str1) {
    return str1.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
  },

  createBreadcrumbs: function(articlePage, subheadings){

    subheadings.each(function(e, index ) {
      $(this).attr('id', window.smartproduktion.string_parameterize($(this).text()));
    });

    $("span[data-type='article-toc']").each(function(index, el) {
    $(el).html(`<div class="aa-toc"><h3 class='mt-0 mb-4 no-toc'>${el.dataset.title || "Innehållsförteckning"}</h3><ol class="${el.dataset.style || "toc-regular"}"></ol></div>`);
  
      subheadings.each(function(index, el) {
        if (el.className.includes("no-toc")) {
          return;
        }
  
        let textToShow = $(el).contents().filter(function() {
          let string = this.data
          return window.smartproduktion.removeHTML(string);
        }).text();
    
        let cust = ""

        if (el.tagName == "H3" || el.tagName == "H4") {
          cust = "<span class='text-color-text-important'>↳</span>"
    
          $('.aa-toc ol').find("ul").last().append(`<div class=""> ${cust} <a class='text-color-text-important small-text' href='#${$(el).attr('id')}'>${textToShow}</a></div>`)
        } else {
          $('.aa-toc ol').append('<li' + (index > 10 ? ' class="hidden"' : '') + '>' + cust + '<a class="bold" href="#' + $(el).attr('id') + '">' + textToShow + '</a><ul style="padding: 0;"></ul></li>');
        }
      });
    
      let numHiddenElements = $('.aa-toc li:hidden').length
    
      if (numHiddenElements > 0) {
        $('.aa-toc').append(`
          <div style="list-style: none!important;" class="toc-expand cursor btn btn-small btn-outline ">
            Visa alla ${numHiddenElements + 10} rader
          </div>
        `);
      }
    });

    $(".toc-expand").on("click", function() {
      $('.aa-toc li').removeClass('hidden');
      $('.toc-expand').hide();
      return false;
    }) 
  },


  removeHTML(str){ 
    let doc = new DOMParser().parseFromString(str, 'text/html');
    return doc.body.textContent || "";
  },


}


function buildBreadcrumbs(){
  // ARTICLES BREADCRUMBS
  let articlePage = document.querySelector(".article-page-content")


  if(articlePage) {
    window.article_object.createBreadcrumbs(articlePage, $(articlePage).find("h2, h3, h4"));
  }
}


// FUnction that adds link-email to every email on the page
function addEmailLinks(){
  $("a[href^='mailto:']").each(function() {
    $(this).addClass("link-email");
  });
}

// Function that adds link-phone to every phone number on the page
function addPhoneLinks(){
  $("a[href^='tel:']").each(function() {
    $(this).addClass("link-phone");
  });
}


/**
 * Enhances the file upload functionality by adding event listeners and handling direct uploads.
 */
function enhanceFileUploadFunctionality() {
  const dragFilesContainer = document.querySelector(".drag-files-container");

  function removeDefaultClickBehavior(event) {
    event.preventDefault();
  }

  function removeActiveBorder() {
    dragFilesContainer.querySelector("label").classList.remove("active-border");
  }

  function addActiveBorder() {
    dragFilesContainer.querySelector("label").classList.add("active-border");
  }

  function createDirectUploadElement(id, file) {
    const directUploadElement = document.createElement("div");
    directUploadElement.id = `direct-upload-${id}`;
    directUploadElement.className = "direct-upload direct-upload--pending";
    directUploadElement.innerHTML = `
      <div id='direct-upload-progress-${id}' class='direct-upload__progress' style='width: 0%'></div>
      <span class='direct-upload__filename'>${file.name}</span>
    `;
    return directUploadElement;
  }

  function handleDirectUploadInitialize(event) {
    const { target, detail } = event;
    const { id, file } = detail;
    const directUploadElement = createDirectUploadElement(id, file);
    target.insertAdjacentElement("beforebegin", directUploadElement);
  }

  function handleDirectUploadStart(event) {
    const { id } = event.detail;
    const directUploadElement = document.getElementById(`direct-upload-${id}`);
    directUploadElement.classList.remove("direct-upload--pending");
  }

  function handleDirectUploadProgress(event) {
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    progressElement.style.width = `${progress}%`;
  }

  function handleDirectUploadError(event) {
    event.preventDefault();
    const { id, error } = event.detail;
    const directUploadElement = document.getElementById(`direct-upload-${id}`);
    directUploadElement.classList.add("direct-upload--error");
    directUploadElement.setAttribute("title", error);
  }

  function handleDirectUploadEnd(event) {
    const { id } = event.detail;
    const directUploadElement = document.getElementById(`direct-upload-${id}`);
    directUploadElement.classList.add("direct-upload--complete");
  }

  function handleDndUploadError(event) {
    event.preventDefault();

    window.smartproduktion.showNotification(
      "Opps... Något gick fel när du försökte ladda upp filen.",
      true
    );
    alert(`
      Opps... Något gick fel när du försökte ladda upp filen. 
      Filformaten vi accepterar är image/png, image/jpeg, image/gif, image/tiff och bilderna måste vara mindre än 5mb. 
    `);
  }

  function handleDndUploadEnd(event) {
    event.preventDefault();
  }

  document.addEventListener("click", (event) => {
    if (event.target.matches("a.direct-upload__remove")) {
      removeDefaultClickBehavior(event);
    }
  });

  if (dragFilesContainer){
    dragFilesContainer.addEventListener("drop", removeActiveBorder);
    dragFilesContainer.addEventListener("dragenter", addActiveBorder);
    dragFilesContainer.addEventListener("dragleave", removeActiveBorder);
  }

  document.addEventListener("direct-upload:initialize", handleDirectUploadInitialize);
  document.addEventListener("direct-upload:start", handleDirectUploadStart);
  document.addEventListener("direct-upload:progress", handleDirectUploadProgress);
  document.addEventListener("direct-upload:error", handleDirectUploadError);
  document.addEventListener("direct-upload:end", handleDirectUploadEnd);
  document.addEventListener("dnd-upload:error", handleDndUploadError);
  document.addEventListener("dnd-uploads:end", handleDndUploadEnd);
}


/**
 * Enhances the functionality of buttons by adding SVG icons to specific button types.
 */
function enhanceButtonFunctionality() {
  const addSvgIconsToLinks = (links, svg) => {
    Array.from(links).forEach((item) => {
      if (item != undefined && !item.innerHTML.includes("svg-link")) {
        item.innerHTML += svg;
      }
    });
  };

  const handlePopupButtonClick = (e) => {
    e.preventDefault();
  };

  const handleSlideButtonClick = (e) => {
    e.preventDefault();
    if (e.currentTarget != undefined && this.hash !== "") {
      e.preventDefault();
      let hash = this.hash;

      // Using smooth scroll behavior to scroll to the specified area
      document.querySelector(hash).scrollIntoView({ behavior: "smooth" });
    }
  };

  const arrowLinks = document.querySelectorAll(".btn-arrow");
  const popupLinks = document.querySelectorAll(".btn-popup");
  const externalLinks = document.querySelectorAll(".btn-external");
  const expandLinks = document.querySelectorAll(".btn-expand");
  const slideLinks = document.querySelectorAll(".btn-slide");
  const downloadLinks = document.querySelectorAll(".btn-download");

  const arrowSvg = `<svg aria-hidden='true' class='svg-fill-link svg-link' viewBox='0 0 16 16' xmlns='https://www.w3.org/2000/svg'><path d='M5.293 2.709A1 1 0 0 1 6.71 1.293l6.3 6.3a1 1 0 0 1 0 1.414l-6.3 6.3a1 1 0 0 1-1.416-1.416L10.884 8.3z' fill-rule='evenodd'></path></svg>`;
  const expandSvg = `<svg viewBox="0 0 48 48" class='svg-stroke-link svg-link' height="48" width="48" xmlns="https://www.w3.org/2000/svg"><g transform="matrix(2,0,0,2,0,0)"><path d="M1.000 12.000 A11.000 11.000 0 1 0 23.000 12.000 A11.000 11.000 0 1 0 1.000 12.000 Z" fill="none"  stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 6L12 18" fill="none"  stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 12L6 12" fill="none"  stroke-linecap="round" stroke-linejoin="round"></path></g></svg>`;
  const popupSvg = `<svg fill="none" viewBox="0 0 140 140"  class='svg-stroke-link svg-link' height="140" width="140" xmlns="https://www.w3.org/2000/svg"><g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)"><path d="M19.35,11.311a1,1,0,0,1,0,1.379C18.148,13.951,15.31,16.5,12,16.5s-6.147-2.549-7.348-3.81a1,1,0,0,1,0-1.379C5.852,10.05,8.69,7.5,12,7.5S18.149,10.05,19.35,11.311Z"   stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.500 12.000 A2.500 2.500 0 1 0 14.500 12.000 A2.500 2.500 0 1 0 9.500 12.000 Z"   stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.253,3.075a11.506,11.506,0,0,1,3.888,6.073"   stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.53.768a11.5,11.5,0,0,1,7.173.738"   stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.673,6.941A11.492,11.492,0,0,1,6.715,1.786"   stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.612,16.933a11.5,11.5,0,0,1-.9-7.155"   stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.394,23.2a11.5,11.5,0,0,1-6.157-3.754"  stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.143,21.012a11.485,11.485,0,0,1-6.77,2.482"   stroke-linecap="round" stroke-linejoin="round"></path><path d="M23.5,12.02a11.5,11.5,0,0,1-2.273,6.843"   stroke-linecap="round" stroke-linejoin="round"></path></g></svg>`;
  const externalLinkSvg = `<svg class='external-link-svg svg-fill-link svg-link svg-link-14' xmlns="https://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="64px" height="64px"><path d="M 84 11 C 82.3 11 81 12.3 81 14 C 81 15.7 82.3 17 84 17 L 106.80078 17 L 60.400391 63.400391 C 59.200391 64.600391 59.200391 66.499609 60.400391 67.599609 C 61.000391 68.199609 61.8 68.5 62.5 68.5 C 63.2 68.5 63.999609 68.199609 64.599609 67.599609 L 111 21.199219 L 111 44 C 111 45.7 112.3 47 114 47 C 115.7 47 117 45.7 117 44 L 117 14 C 117 12.3 115.7 11 114 11 L 84 11 z M 24 31 C 16.8 31 11 36.8 11 44 L 11 104 C 11 111.2 16.8 117 24 117 L 84 117 C 91.2 117 97 111.2 97 104 L 97 59 C 97 57.3 95.7 56 94 56 C 92.3 56 91 57.3 91 59 L 91 104 C 91 107.9 87.9 111 84 111 L 24 111 C 20.1 111 17 107.9 17 104 L 17 44 C 17 40.1 20.1 37 24 37 L 69 37 C 70.7 37 72 35.7 72 34 C 72 32.3 70.7 31 69 31 L 24 31 z"/></svg>`;
  const slideSvg = `<svg viewBox="0 0 140 140" class='svg-stroke-link svg-link' height="140" width="140" xmlns="https://www.w3.org/2000/svg"><g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)"><path d="M12 23.221L12 0.757" fill="none"  stroke-linecap="round" stroke-linejoin="round"></path><path d="M1,11.257,11.249,22.9a1,1,0,0,0,1.5,0L23,11.257" fill="none"  stroke-linecap="round" stroke-linejoin="round"></path></g></svg>`;
  const downloadSvg = `<svg viewBox="0 0 140 140" height="140" width="140" class='svg-stroke-link svg-link' xmlns="https://www.w3.org/2000/svg"><g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)"><path d="M23,18.218v1.913A2.87,2.87,0,0,1,20.131,23H3.869A2.869,2.869,0,0,1,1,20.131V18.218" fill="none"  stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 18.108L12 1" fill="none"  stroke-linecap="round" stroke-linejoin="round"></path><path d="M19 11.108L12 18.108 5 11.108" fill="none"  stroke-linecap="round" stroke-linejoin="round"></path></g></svg>`;

  addSvgIconsToLinks(arrowLinks, arrowSvg);
  addSvgIconsToLinks(expandLinks, expandSvg);
  addSvgIconsToLinks(popupLinks, popupSvg);
  addSvgIconsToLinks(externalLinks, externalLinkSvg);
  addSvgIconsToLinks(slideLinks, slideSvg);
  addSvgIconsToLinks(downloadLinks, downloadSvg);

  popupLinks.forEach((link) => {
    link.addEventListener("click", handlePopupButtonClick);
  });

  slideLinks.forEach((link) => {
    link.addEventListener("click", handleSlideButtonClick);
  });
}


// This function handles the image popup functionality.
// It creates a popup with an image when an image element with the class "popup_image" is clicked.
// The popup includes the clicked image and allows the user to close the popup by clicking on it.
function handleImagePopup() {
  const createImagePopup = (src) => {
    console.log(`Open image popup for ${src}`)
    const imagePopup = document.createElement("div");
    
    imagePopup.classList.add("image__popup");
    imagePopup.innerHTML = `
      <div>
        <img src="${src}" loading="lazy" />
      </div>
      <span class="fixed top-6 right-4 cross-closer">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><line data-v-91f9f1d0="" x1="18" y1="6" x2="6" y2="18"></line> <line data-v-91f9f1d0="" x1="6" y1="6" x2="18" y2="18"></line></svg>
      </span>
    `;
    return imagePopup;
  };

  const handleImageClick = (e) => {
    const body = document.querySelector("body");
    const imagePopup = createImagePopup(e.currentTarget.src);

    body.appendChild(imagePopup);
    body.classList.add("stickyScroll");
    imagePopup.classList.remove("hidden");

    const replaceImageSrc = (src) => {
      const replacements = [
        { from: "/thumb/", to: "/original/" },
        { from: "/medium/", to: "/original/" },
        { from: "/large/", to: "/original/" }
      ];

      for (const replacement of replacements) {
        if (src.includes(replacement.from)) {
          return src.replace(replacement.from, replacement.to);
        }
      }

      return src;
    };

    const closeImagePopup = () => {
      imagePopup.remove();
      body.classList.remove("stickyScroll");
    };

    const image = imagePopup.querySelector("img");
    image.src = replaceImageSrc(image.src);

    imagePopup.addEventListener("click", closeImagePopup);
    imagePopup.querySelector("div").addEventListener("click", closeImagePopup);
  };

  const imageElements = document.querySelectorAll(".popup_image");
  imageElements.forEach((image) => {
    image.addEventListener("click", handleImageClick);
  });
}


/**
 * Initializes the marketing functions.
 * This function is responsible for handling specific functionality related to marketing.
*/
function initializeMarketingFunctions() {
  const loadImages = () => {
    Array.from(document.querySelectorAll(".team-members-view img")).map(
      (item) => {
        item.src = item.src.replace("medium", "large");
      }
    );
  }

  /** Closes all popups and restores the page state. */
  const closePopups = () => {
    // Escape with keyboard for popup
    $(document).on("keydown", function (e) {
      if (e.key === "Escape" && $("body").hasClass("stickyScroll")) {
        $("body").removeClass("stickyScroll");
        $(".content_popup").addClass("hidden");
        $(".popup").addClass("hidden");
        $(".image__popup").remove()
        $("#footer").removeClass("hidden");
      }
    });

    $(".close").on("click", function (e) {
      $(".content_popup").addClass("hidden");
      $("#footer").removeClass("hidden");
      $(".cart-hide-view").addClass("hidden");
    });
  }

  /** Handles the spinner functionality.*/
  const handleSpinner = () => {
    // Spinner for when we redirecting to another page / webhook
    $("[data-behavior~=open-spinner]").on("click", function (e) {
      $(".popup-overlay").removeClass("hidden");
      $(".popup-overlay").addClass("z-spinner");
      $(".popup-overlay").append(`<div class='popup-spinner'><div class='simple-spinner'><div></div><div></div></div></div>`);
    });
  }

  const preventScroll = () => {
    // Remove scroll functionality on number within forms 
    $("form").on("focus", "input[type=number]", function (e) {
      $(this).on("wheel.disableScroll", function (e) {
        e.preventDefault();
      });
    });

    $("form").on("blur", "input[type=number]", function (e) {
      $(this).off("wheel.disableScroll");
    });
  }

  // Fix for Safari where label doesn't work when uploading images
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf("safari") !== -1 && userAgent.indexOf("chrome") === -1) {
    $(".file-input-upload-media").removeClass("file-input-upload-media");
  }

  // Prevent links with the class "prevent_ahref" from being clicked
  const preventLinks = () => {
    const links = document.querySelectorAll(".prevent_ahref");
    links.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
      });
    });
  };

  const implementLocalStorageOnForm = () => {
    let customerName =
      localStorage.getItem(window.smartproduktion.currentDomain + "_name") ||
      "";
    let customerEmail =
      localStorage.getItem(window.smartproduktion.currentDomain + "_email") ||
      "";
    let customerPhone =
      localStorage.getItem(window.smartproduktion.currentDomain + "_phone") ||
      "";
    let customerAddress =
      localStorage.getItem(
        window.smartproduktion.currentDomain + "_address"
      ) || "";
    let customerZipcode =
      localStorage.getItem(
        window.smartproduktion.currentDomain + "_zipcode"
      ) || "";

    [
      {
        target: "name",
        value: customerName,
      },
      {
        target: "email",
        value: customerEmail,
      },
      {
        target: "phone",
        value: customerPhone,
      },
      {
        target: "zipcode",
        value: customerZipcode,
      },
      {
        target: "address",
        value: customerAddress,
      },
    ].forEach((targetItem) => {
      Array.from(
        document.querySelectorAll(
          `[data-localstoragename='${targetItem["target"]}']`
        )
      ).map((item) => {
        item.value = targetItem["value"];
      });
    });
  }

  const loadPopupFunctionality = () => {
    const secondsUntilPopup = 15;
    let seconds = 0;

    const incrementSeconds = () => {
      seconds++;
    };

    setInterval(incrementSeconds, 1000);

    const setCookie = (name, value, expirationDays) => {
      const date = new Date();
      date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    const checkIfUndefined = (element) => {
      return typeof element !== "undefined" && element !== null;
    };

    const showPopup = () => {
      setCookie("popup_cookie", "normal", 1);
      $("#popup-normal-cta").removeClass("hidden");
      $("body").addClass("stickyScroll");
    };

    const hidePopup = () => {
      $("#popup-normal-cta").addClass("hidden");
      $("body").removeClass("stickyScroll");
    };

    const handleScroll = () => {
      const scrollTop = $(window).scrollTop();
      const totalWindowHeight = $(window).height();
      const totalPageHeight = $(document).height() - totalWindowHeight;
      const findBreakingPointForPopup = totalPageHeight / 2;

      if (
        scrollTop > findBreakingPointForPopup &&
        seconds >= secondsUntilPopup &&
        window.smartproduktion.getCookie("popup_cookie") === ""
      ) {
        showPopup();
      }
    };

    const handlePopupClose = () => {
      hidePopup();
    };

    const initializePopupFunctionality = () => {
      const popupElement = document.querySelector("#popup-normal-cta");
      if (checkIfUndefined(popupElement)) {
        $(document).on("scroll load", handleScroll);

        $(".popupBackgroundCta, .customDomainPopupCTA--Cross").on(
          "click",
          handlePopupClose
        );
      }
    };

    initializePopupFunctionality();
  };


  loadPopupFunctionality();
  implementLocalStorageOnForm();
  preventLinks();
  handleSpinner();
  preventScroll();
  closePopups();
  loadImages();
}

/* Should only be used on the domain and not CustomDomain for Marketing */
function domainMarketingFunctionsOnly(){
  const positionAndLocation = () => {
    function sendPosition(position) {
      let query = `latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`;
      window.location.href = `/utforska?${query}`;
    }

    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(sendPosition);
      } 
      else {
        alert("Geolokalisering stöds inte av den här webbläsaren");
      }
    }

    $(".location-zipcode-pin").on("click", function () {
      getLocation();

      // Google Analytics Tracking & Ahoy Tracking below
      let locTitle = "Hämta plats(long, lat) genom klick";
      ahoy.track(`${locTitle}`,window.smartproduktion.domainAndBrowserInformation);

      if (typeof ga === "function") {
        ga(
          "send",
          "event",
          "Clicks",
          `${locTitle} (från URL: ${window.location.href})`
        );
      }
    });
  }  

  const votingFunctionality = () => {
    const votes = document.querySelectorAll(".vote");
    Array.from(votes).forEach(function (vote) {
      vote.addEventListener("click", function (e) {
        e.preventDefault();
        if (!e.currentTarget.classList.contains("activeVote")) {
          e.currentTarget.classList.add("activeVote");
          const getClickedVote = $(
            "#vote_" + e.currentTarget.dataset.voteId + "  .vote--number"
          );
          // // console.log('Inside:' + e.currentTarget.dataset.voteId)
          const foretag_id = e.currentTarget.dataset.voteId;

          const getValue = getClickedVote.text();
          getClickedVote.html(parseInt(getValue) + 1);

          $.ajax({
            url: "/upvote?foretag_id=" + foretag_id,
            type: "post",
            dataType: "json",
          });
        }
      });
    });
  }
  
  const landingPageLoop = () => {
    // browsers and screens for 'how it works'
    const duration = 3500;
    let loadCounter = 0;

    function start() {
      $(".progress-bar")
        .stop()
        .css("width", 0)
        .animate(
          {
            width: 100 + "%",
          },
          {
            duration: duration,
            progress: function (promise, progress, ms) {
              $(this).text(Math.round(progress * 100) + "%");
            },
          }
        );
    }

    function loopPromoScreens() {
      $(".page-header .floating-slider .browser-slider").removeClass(
        "is-visible"
      );

      if (loadCounter == 1) {
        $(
          ".page-header .floating-slider .browser-navigation .browser-title"
        ).html("Företagssida");
        $(".page-header .floating-slider .browser-company-page").addClass(
          "is-visible"
        );
      }

      if (loadCounter == 2) {
        $(".page-header .browser-navigation .browser-title").html(
          "Företagsinställningar"
        );
        $(".page-header .floating-slider .browser-panel").addClass("is-visible");
      }

      if (loadCounter == 3) {
        $(".page-header .browser-navigation .browser-title").html(
          "Kontrollpanelen"
        );
        $(".page-header .floating-slider .browser-dashboard-page").addClass(
          "is-visible"
        );
      }

      if (loadCounter == 4) {
        $(
          ".page-header .floating-slider .browser-navigation .browser-title"
        ).html("Uppdatera företagsprofil");
        $(".page-header .floating-slider .browser-update-profile-page").addClass(
          "is-visible"
        );
      }
    }

    setInterval(function (e) {
      if (loadCounter == 4) {
        loadCounter = 1;
      } else {
        loadCounter++;
      }
      start();
      loopPromoScreens();
    }, duration);
  }

  const loadingMaps = () => {
    $("#mini-map").addClass("expand-map");
    $("#large-map").removeClass("hidden");

    // Maps
    const getCurrentPrimaryColor = document.querySelector("#full_application");
    if ( getCurrentPrimaryColor != undefined && getCurrentPrimaryColor.dataset != undefined) {
      var getCurrentPrimaryColorValue = getCurrentPrimaryColor.dataset.color;
    }

    const customStyle = [
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#e9e9e9",
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 17,
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 29,
          },
          {
            weight: 0.2,
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 18,
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#dedede",
          },
          {
            lightness: 21,
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ffffff",
          },
          {
            lightness: 16,
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            saturation: 36,
          },
          {
            color: "#333333",
          },
          {
            lightness: 40,
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [
          {
            color: "#f2f2f2",
          },
          {
            lightness: 19,
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#fefefe",
          },
          {
            lightness: 20,
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#fefefe",
          },
          {
            lightness: 17,
          },
          {
            weight: 1.2,
          },
        ],
      },
    ];

    const simpleElegantMapStyle = [
      {
        featureType: "all",
        elementType: "all",
        stylers: [
          {
            saturation: "32",
          },
          {
            lightness: "-3",
          },
          {
            visibility: "on",
          },
          {
            weight: "1.18",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "labels",
      },
      {
        featureType: "landscape",
        elementType: "labels",
      },
      {
        featureType: "landscape.man_made",
        elementType: "all",
        stylers: [
          {
            saturation: "-70",
          },
          {
            lightness: "14",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            saturation: "100",
          },
          {
            lightness: "-14",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels",
        stylers: [
          {
            lightness: "12",
          },
        ],
      },
    ];

    ["#map", "#mini-map", "#large-map"].forEach(function (currentMap) {
      var mapper = document.querySelector(currentMap);
      if (mapper != undefined || mapper != null) {
        var map;

        if (currentMap == "#map") {
          var map = new GMaps({
            div: currentMap,
            lat: 62.3965876,
            lng: 14.1266019,
            disableDefaultUI: true,
            styles: customStyle,
          });
        }

        if (currentMap == "#large-map") {
          var map = new GMaps({
            div: currentMap,
            lat: 62.3965876,
            lng: 14.1266019,
            panControl: true,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false,
            styles: simpleElegantMapStyle,
          });
        }

        if (currentMap == "#mini-map") {
          map = new GMaps({
            div: currentMap,
            lat: 62.3965876,
            lng: 14.1266019,
            disableDefaultUI: false,
            styles: customStyle,
          });
        }

        window.map = map;

        const locationsDataset =
          document.querySelector(currentMap).dataset.locations;
        const locationDataset =
          document.querySelector(currentMap).dataset.location;

        const icon = {
          path: "M256,0C167.641,0,96,71.625,96,160c0,24.75,5.625,48.219,15.672,69.125C112.234,230.313,256,512,256,512l142.594-279.375C409.719,210.844,416,186.156,416,160C416,71.625,344.375,0,256,0z M256,256c-53.016,0-96-43-96-96s42.984-96,96-96c53,0,96,43,96,96S309,256,256,256z",
          fillColor: getCurrentPrimaryColorValue,
          fillOpacity: 0.95,
          anchor: new google.maps.Point(0, 0),
          strokeWeight: 0,
          scale: 0.04,
          scaledSize: new google.maps.Size(64, 64),
        };

        const bounds = new google.maps.LatLngBounds();

        if (locationsDataset) {
          const locations = JSON.parse(
            document.querySelector(currentMap).dataset.locations
          );

          window.locations = locations;
          locations.forEach(function (location) {
            if (location.longitude && location.latitude) {
              const marker = map.addMarker({
                lat: location.latitude,
                lng: location.longitude,
                title: location.name,
                icon: icon,
                infoWindow: {
                  content: location.name,
                },
              });

              bounds.extend(marker.position);
              setTimeout(function () {
                // map.setCenter(location.latitude - 0.04, location.longitude - 0.4)
              }, 1000);
              // setTimeout(function () {
              //   map.setZoom(12);
              // }, 1000)
            }
          });
        } else if (locationDataset) {
          const single_location = JSON.parse(
            document.querySelector(currentMap).dataset.location
          );
          window.single_location = location;

          if (single_location.longitude && single_location.latitude) {
            const marker = map.addMarker({
              lat: single_location.latitude,
              lng: single_location.longitude,
              title: single_location.name,
              icon: icon,
              infoWindow: {
                content: single_location.name,
              },
            });

            bounds.extend(marker.position);

            setTimeout(function () {
              map.setZoom(12);
              // map.setCenter(single_location.latitude - 0.003  , single_location.longitude - 0.13)
            }, 1000);
          }
        }

        const selectCurrentMap = document.querySelector(currentMap);
        if (selectCurrentMap) {
          var l = document.querySelector(currentMap).dataset.l;
        }

        if (l) {
          const latlngs = l.split(",");
          const southWest = new google.maps.LatLng(latlngs[0], latlngs[1]);
          const northEast = new google.maps.LatLng(latlngs[2], latlngs[3]);
          const bounds = new google.maps.LatLngBounds(southWest, northEast);
          map.fitBounds(bounds, 0);
        } else {
          map.fitZoom();
          if (currentMap == "#mini-map") {
            setTimeout(function () {
              map.setZoom(12);
            }, 1000);
          }
        }
        const redoSearch = document.querySelector("#redo-search");
        if (redoSearch) {
          redoSearch.addEventListener("click", function (e) {
            e.preventDefault();
            const bounds = map.getBounds();
            const new_location =
              bounds.getSouthWest().toUrlValue() +
              "," +
              bounds.getNorthEast().toUrlValue();
          });
        }
      }
    });

    window.compare_site.toggleBetweenHelpAndCompareFunctionality("instructions-tab");
    window.smartproduktion.getZipCodeCookieAndConnect();
  }

  const handleNewCompany = () => {
    const openNewCompanyOnClick = document.querySelector("#open-new-company-on-click");
    const newCompanyForm = document.querySelector("#new-company-form");

    if (openNewCompanyOnClick != null) {
      openNewCompanyOnClick.addEventListener("click", function (e) {
        e.target.classList.add("hidden");
        newCompanyForm.classList.remove("hidden");
      });
    }
  }

  const tabs = () => {
    // Tabs
    let tabs = document.querySelector(".tabs");
    let allTabs = document.querySelectorAll(".tab");
    let contentWrapper = document.querySelector(".wrapper_content");

    if ( window.smartproduktion.checkIfUndefined(tabs) && window.smartproduktion.checkIfUndefined(contentWrapper) ) {
      tabs.addEventListener("click", function (e) {
        for (let i = 0; i < allTabs.length; i++) {
          $("#" + allTabs[i].id).removeClass("active");
        }
        $("#" + e.target.id).addClass("active");
      });

      $(window).on("resize scroll", function (e) {
        let windowWidth = $(window).width();

        if (windowWidth > 520) {
          $(".tabs-active").css({
            width: contentWrapper.clientWidth,
          });
          $(".tabs-active .add_company").css({
            marginLeft: "auto",
          });
        }

        if (windowWidth < 520) {
          $(".tabs-active").css({
            width: "100%",
            overflowY: "scroll",
          });

          $(".tabs-active .add_company").css({
            marginLeft: "15px",
          });

          $(".relative-tabs").css({
            width: contentWrapper.clientWidth,
          });
        }

        if ($(this).scrollTop() >= $(".relative-tabs").offset().top) {
          let tabsClientHeight = tabs.clientHeight + "px";

          $(".tabs").addClass("tabs-active");
          $(".relative-tabs-addon").css({
            height: tabsClientHeight,
          });
        } else {
          $(".tabs").removeClass("tabs-active");
          $(".relative-tabs-addon").css({
            height: "auto",
          });
        }
      });
    }
  }

  const inititalizeStandards = () => {
    // Add company
    const getDomain = document.querySelector("#full_application");

    if (getDomain) { 
      // currentDomain
      window.smartproduktion.currentDomain = getDomain.dataset.domain;
      
      if ( window.smartproduktion.checkIfUndefined(getDomain.dataset["domainId"])) {
        window.smartproduktion.domain_id = parseInt(
          getDomain.dataset["domainId"]
        );
      }

      window.smartproduktion.domainAndBrowserInformation = {
        domain: window.smartproduktion.currentDomain,
        domain_id: window.smartproduktion.domain_id,
        href: window.location.href,
        referrer: document.referrer,
      };
    }
    
    $(".new_customer").on("submit", function (e) {
      $("#send-cart").html(`<i class='fal fa-spinner fa-spin'></i>`);
      localStorage.clear();
    });

    // Visa utforskan baserat på sortering från ex filter vyn
    $("#show-companies-based-on-sort").on("click", function (e) {
      e.preventDefault();
      const formCompanies = document.querySelector("#section-filter-radio-buttons");
      if (!formCompanies){
        return;
      }
      const formValue = formCompanies.querySelector("input:checked").value;

      window.location.href = `/utforska?sort=${formValue}`;
    });

    $("ul#filter_menu li").on("hover", function () {
        $(this).children("ul.drop").delay(20).slideDown(100);
        $(this).addClass("active_a");
      },

      function () {
        $(this).children("ul.drop").delay(20).slideUp(100);
        $(this).removeClass("active_a");
      }
    );

    $("#footer").removeClass("hidden");
    $(".alla-boxar").removeClass("hidden");
    $(".loading_screen").addClass("slow_hidden");
    $(".display_cart_when_loaded").removeClass("hidden");
    $(".company").removeClass("hidden");
    $(".spinner").addClass("hidden");
    $(".load_spinner").addClass("hidden");
  }

  /**
   * Opens a popup modal when the "buttonOpenSend" element is clicked.
   * Adds necessary classes and performs tracking actions.
   * Updates the browser history with a new state.
   */
  const openPopupFunction = () => {
    $(".buttonOpenSend").on("click", function (e) {
      $("body").addClass("stickyScroll");
      $("#footer").addClass("hidden");
      $(".cart-hide-view").removeClass("hidden");

      ahoy.track("Klickade på gratis prisförfrågan", window.location.href);

      if (typeof ga === "function") {
        ga(
          "send",
          "event",
          "Clicks",
          "Öppnade Gratis Prisförfrågan(Popup)",
          window.location.href
        );
      }

      // History push must be after tracking software
      history.pushState("", "Popup Modal", "/offert");
    });
  }

  const handlePriceChanger = () => {
      // Pricing
    $("#account-pricing-changer li").on("click", function (e) {
      $("ul#account-pricing-changer li").removeClass("active");
      $(".pricing-year-vs-month li#pricing-month").addClass("active");
      $(".pricing-year-vs-month li#pricing-year").removeClass("active");
      $(e.target).addClass("active");

      if ($("ul#account-pricing-changer li#pricing-free").hasClass("active")) {
        $(".actual-pricing-section h3").html("0");
        $(".actual-pricing-section p").html("kronor");
        $(".pricing-year-vs-month").addClass("hidden");
        $(".pricing-functionality h5 span").html("gratisanvändare");
        $(".pricing-functionality li.premium").removeClass("active");
        $(".unique_page .basic-functionality ul li div.premium").removeClass(
          "active"
        );
        $(".unique_page .basic-functionality ul li div.free").addClass("active");
        $(".soon").addClass("hidden");
        $("#feature-filter h6 span").html("5st");
        $("#feature-tags h6 span").html("5st");

        $(".unlimited-functionality h6 span").html("5st");
      }
      
      if ($("ul#account-pricing-changer li#pricing-premium").hasClass("active")) {
        const getPrice = document.querySelector("body").dataset.priceCompareWebsite;
        $(".actual-pricing-section h3").html(getPrice);
        $(".actual-pricing-section p").html("kronor per år");
        $(".pricing-year-vs-month").removeClass("hidden");
        $(".pricing-functionality h5 span").html("premiumanvändare");
        $(".pricing-functionality li.premium").addClass("active");
        $(".unique_page .basic-functionality ul li div.premium").addClass(
          "active"
        );
        $(".soon").removeClass("hidden");
        $("#feature-filter h6 span").html("Obegränsat med ");
        $("#feature-tags h6 span").html("Obegränsat med ");

        $(".unlimited-functionality h6 span").html("Obegränsat med");
      }
    });

    // Pricing Year Vs Month
    $(".pricing-year-vs-month li").on("click", function (e) {
      $(".pricing-year-vs-month li").removeClass("active");
      $(e.target).addClass("active");

      if ($(".pricing-year-vs-month li#pricing-month").hasClass("active")) {
        $(".actual-pricing-section h3").html("695");
        $(".actual-pricing-section p").html("kronor i månaden");
      }

      if ($(".pricing-year-vs-month li#pricing-year").hasClass("active")) {
        $(".actual-pricing-section h3").html("4995");
        $(".actual-pricing-section p").html("kronor om året");
      }
    });
  }
  
  inititalizeStandards();
  openPopupFunction();
  handleNewCompany();
  landingPageLoop();
  votingFunctionality();
  handlePriceChanger();
  tabs();
  positionAndLocation();
  cartList.loadCart();

  setTimeout(() => {
    loadingMaps()
  }, 100);
}

function customDomainMarketingFunctionsOnly(){
  const zoomInOnTailwindTheme = () => {
    // Zoom in on images in the customer view if Tailwind
    const addZoomToImages = document.querySelector("body.CustomerView .targetZoom");
    if (window.smartproduktion.checkIfUndefined(addZoomToImages)) {
      setTimeout(() => {
        $(".customDomainHeaderCoverImage").addClass("addZoomToImage");
      }, 0);
    }
  }
  
  const tableOfContentsSection = () => {
      // Table of content for article
    const tableOfContent = document.querySelector("#table-of-content");
    $("#table-of-content").removeClass("hidden");
    
    if (tableOfContent != undefined && tableOfContent != "null") {
      const allSubtitles = document.querySelectorAll("h2.subtitle");

      Array.from(allSubtitles).map(function (title) {
        // console.log(title)
        let contentWithinTitle = title.innerHTML;

        if (title.id != undefined) {
          var idOfTitle = `<a href='#${title.id}' class='btn btn-small btn-link btn-slide'>Klicka för att läsa mer</a>`;
        } else {
          var idOfTitle = ``;
        }

        tableOfContent.innerHTML += `<div><h4 class='flex color-fill-path-inside-element items-center'>${contentWithinTitle}</h4>
          ${idOfTitle}
        </div>`;
      });
    }
  }
  
  zoomInOnTailwindTheme();
  tableOfContentsSection();
}

function initializeStarRating() {
  const stars = $("#stars");
  const reviewActionBtn = $(".review_action .btn");
  const submitReviewBtn = $("#submit_review");
  if (stars.length === 0) {
    return;
  }
  stars.raty({
    path: '',
    scoreName: 'review[star]',
    score: 1,
    starOff: 'https://s3.amazonaws.com/smidigarese/static_images/star-off.png',
    starOn: 'https://s3.amazonaws.com/smidigarese/static_images/star-on.png'
  });

  stars.find("img").on("click", function(e) {
    reviewActionBtn.removeClass("gray-disabled-button");
    reviewActionBtn.prop("disabled", false);
  });

  submitReviewBtn.on("click", function() {
    $(this).text("Sparar...");
  });
  
}

function handleNavigationAndQuoteToggles() {
  const menuLinkOpen = document.querySelector(".menu-link-open");
  const navigationMenu = document.querySelector(".data-navigation-menu");
  const menuLinkOpenSvg = document.querySelector(".menu-link-open svg");
  const smartproduktionQuoteWebsites = document.querySelector(".SmartproduktionQuoteWebsites");
  const smartproduktionQuoteWebsitesTargetSvg = document.querySelector(".SmartproduktionQuoteWebsitesTarget svg");

  document.addEventListener("click", function (e) {
    if (e.target.matches("[data-behavior~='custom-open-navigation']")) {
      e.preventDefault();
      menuLinkOpen.classList.toggle("bold");
      navigationMenu.classList.toggle("hidden");
      menuLinkOpenSvg.classList.toggle("rotate-180");
    }

    if (e.target.matches("[data-behavior~='custom-open-smartproduktion-quote-websites']")) {
      e.preventDefault();
      smartproduktionQuoteWebsites.classList.toggle("hidden");
      smartproduktionQuoteWebsitesTargetSvg.classList.toggle("rotate-180");
    }
  });
}


/**
 * This function is used to modify the behavior of external links within the content of a page.
 * It specifically targets pages within a custom domain application.
 */
function applyExternalElementToLink() {
  const customDomain = document.querySelector('body.custom_domain_application');
  
  if (customDomain){
    document.querySelectorAll(".page-content .externalLinkTextToBlock a").forEach(function(a) {
      if (!a.href.startsWith(window.location.origin) && a.href.indexOf("http") === 0) {
        a.target = "_blank";
        a.classList.add("btn-external");
      }
    });
  }
}

window.addEventListener("DOMContentLoaded", function() {
  addEmailLinks()
  addPhoneLinks()

  applyExternalElementToLink()
  handleNavigationAndQuoteToggles();
  customDomainMarketingFunctionsOnly();
  domainMarketingFunctionsOnly();
  initializeMarketingFunctions();
  
  initializeStarRating();
  handleImagePopup();
  
  setTimeout(() => {
    enhanceButtonFunctionality()
    buildBreadcrumbs()
  }, 0);
    
  // Quote 
  $('#customer_customer_start_date').datepicker();
  $('#customer_customer_end_date').datepicker();

  enhanceFileUploadFunctionality()
});